// REACT AND GATSBY IMPORTS
import React from 'react';
// CONTAINERS, COMPONENTS, AND ELEMENTS
import UsersLayout from '../../components/UsersLayout';
import Button from 'reusecore/src/elements/Button'
// STYLING
// LOGIC
import { login, logout, isLoggedIn, getUser, getUserId } from '../../../../api/UserLoginAPI'

// This really should just act as a container for multiple sections
// smaller content containers
const Test = () => {
  // console.log(props)
  return (
    <UsersLayout seo={{ title: 'test seo' }}>
      <h1>UserLoginAPI Test Code</h1>
      {/* <h4>{JSON.stringify(userData)}</h4> */}
      {/* <h4>{ JSON.stringify(props) }</h4> */}
      <Button title='login' onClick={ () => {
        login() 
      }} />
      <Button title='logout' onClick={ () => {
        logout()
      }} />
      <Button title='isLoggedIn' onClick={ () => {
        console.log( isLoggedIn() )
      }} />
      <Button title='getUser' onClick={ () => {
        console.log( getUser() )
      }} />
      <Button title='getUserId' onClick={ () => {
        console.log( getUserId() )
      }} />

    </UsersLayout>
  );
};

export default Test;
